<!-- eslint-disable -->
<style lang="scss">
.sticky{
  position: sticky;
  top: 0;
  left: 0;
  background-color: #ffffff;
  /* opacity: 1; */
  z-index: 3;
}
  .c-text{
      text-align: left;
      color:#666666;
  }
  .listnumber--12 ,.listnumber{
    li{
      list-style:none;
    }
  }
  .listnumber--12 li{
    &:nth-child(1){
    // background-color: lime;
    margin-left: 20px;
    margin-right: 20px;
    }
    &:nth-child(2){
    // background-color: lime;
    margin-left: 40px;
    margin-right: 30px;
    }
    &:nth-child(3){
    // background-color: lime;
    margin-left: 20px;
    margin-right: 20px;
    }
    &:nth-child(4){
    // background-color: lime;
    margin-left: 40px;
    margin-right: 20px;
    }
    &:nth-child(5){
    // background-color: lime;
    margin-left: 30px;
    margin-right: 20px;
    }
    &:nth-child(6){
    // background-color: lime;
    margin-left: 40px;
    margin-right: 20px;
    }
    &:nth-child(7){
    // background-color: lime;
    margin-left: 40px;
    margin-right: 20px;
    }
    &:nth-child(8){
    // background-color: lime;
    margin-left: 35px;
    margin-right: 20px;
    }
    &:nth-child(9){
    // background-color: lime;
    margin-left: 35px;
    margin-right: 20px;
    }
    &:nth-child(10){
    // background-color: lime;
    margin-left: 35px;
    margin-right: 20px;
    }
    &:nth-child(11){
    // background-color: lime;
    margin-left: 35px;
    margin-right: 20px;
    }
    &:nth-child(12){
    // background-color: lime;
    margin-left: 25px;
    margin-right: 20px;
    }
  }
  @media only screen and (max-width: 900px) {
  .listnumber--12 li{
    &:nth-child(1){
    // background-color: blue;
    margin-left: 20px;
    margin-right: 20px;
    }
    &:nth-child(2){
    // background-color: blue;
    margin-left: 35px;
    margin-right: 20px;
    }
    &:nth-child(3){
    // background-color: blue;
    margin-left: 35px;
    margin-right: 20px;
    }
    &:nth-child(4){
    // background-color: blue;
    margin-left: 35px;
    margin-right: 20px;
    }
    &:nth-child(5){
    // background-color: blue;
    margin-left: 35px;
    margin-right: 20px;
    }
    &:nth-child(6){
    // background-color: blue;
    margin-left: 40px;
    margin-right: 20px;
    }
    &:nth-child(7){
    // background-color: blue;
    margin-left: 35px;
    margin-right: 20px;
    }
    &:nth-child(8){
    // background-color: blue;
    margin-left: 40px;
    margin-right: 20px;
    }
    &:nth-child(9){
    // background-color: blue;
    margin-left: 35px;
    margin-right: 20px;
    }
    &:nth-child(10){
    // background-color: blue;
    margin-left: 35px;
    margin-right: 20px;
    }
    &:nth-child(11){
    // background-color: blue;
    margin-left: 30px;
    margin-right: 20px;
    }
    &:nth-child(12){
    // background-color: blue;
    margin-left: 30px;
    margin-right: 20px;
    }
  }
  }
  .content__pocillos{
    overflow-y: auto;
  }
  .o-pocillos-12 {
    position: relative;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 5px 10px;
    grid-auto-rows: minmax(60px, auto);
    padding: 0;
  }
  .padding-tb{
    padding-top: 1rem !important;
    padding-bottom: 1.5rem !important;
  }
  #app .v-application .v-data-table td {
    padding: 0.5rem 1rem !important;
  }
  #app .v-data-table th .bg-white {
    background-color: #fff !important;
  }
  th.bg--normal__white.text-center {
    padding: 2px !important;
    width: 50%;
    border-bottom: 1px solid;
  }
  
</style>
<template>
  <v-layout row justify-center>
      <v-dialog v-model="dialog"
        persistent
        class="c-modal-pocillos fullscreen-settingtest"
      >
          <v-card class="o-modal-muestras">
              <v-card-text>
                <v-row class="p-0 align-items-center">
                    <v-col
                        cols="12"
                        class="pl-10 text-left"
                      >
                          <span class="fs18"><strong>{{ $t('analisis._ot') }} <span>{{OT}}</span></strong>
                          {{ $t('analisis.la_ot_txt1') }} {{OTNSamples}}
                          {{ (OTNSamples == 1)? $t('analisis.la_ot_muestra_txt3') : $t('analisis.la_ot_muestra_txt2') }}</span>
                      </v-col>
                  </v-row>
                <v-row class="row--principal justify-content-space-around">
                  <v-col cols="3">
                    <v-row class="" >
                      <v-col
                        cols="12"
                        sm="12"
                      >
                        <v-select
                          v-model="F.dataForm.kitPcr"
                          :items="F.kitPCR"
                          item-text="name"
                          item-value="id"
                          :placeholder="$t('analisis.Selectlaboratorytest')"
                          dense
                          @change="filtraEnsayos()"
                          outlined
                        ></v-select>
                      </v-col>
                        <v-col cols="12">
                        <v-simple-table 
                          dense
                          fixed-header
                          height="400px"
                        >
                          <template v-slot:default>
                            <thead>
                                <tr class="">
                                    <th class="bg--normal__white text-center">
                                      <span class="fs14">{{ $t('analisis.muestra') }} </span>
                                    </th>
                                    <th  class="bg--normal__white text-center">
                                      <span class="fs14"> {{ $t('analisis.descripcion') }} </span>
                                     
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="o-modal-muestras__tbody"
                                v-for="item in data.muestras_ots"
                                :key="item.id"
                            >
                                <tr v-if="item.visible === true">
                                    <td class=" border-none text-center" align="center">
                                        <v-checkbox
                                            class="m-auto p-0 text-center"
                                            v-model="item.vmodel"
                                            :label="''+item.id"
                                            color="indigo"
                                            :disabled="item.disabled"
                                            hide-details
                                            @click="clickSample(item)"
                                        ></v-checkbox>
                                    </td>
                                    <td class="fs14 text-center">{{item.descripcion}}</td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                      </v-col> 
                    </v-row>
                  </v-col>

                  <v-col cols="8">
                    <v-row>
                      <v-col cols="12">
                        <v-row class="bb-1 mb-3 justify-content-space-around">
                          <div class="w-150 my-2">
                            <v-checkbox
                              v-model="controles.cn"
                              :label="$t('analisis.c_negativo')"
                              color="red"
                              value="CN"
                              @click="checkCotrol('cn',controles.cn)"
                              class="m-auto p-0 o-modal-muestras__checkbox"
                              hide-details
                            ></v-checkbox>
                          </div>
                          <div class="w-150 my-2">
                            <v-checkbox
                              v-model="controles.cp"
                              :label="$t('analisis.c_positivo')"
                              color="success"
                              value="CP"
                              @click="checkCotrol('cp',controles.cp)"
                              class="m-auto p-0 o-modal-muestras__checkbox"
                              hide-details
                            ></v-checkbox>
                          </div>
                          <div class="w-150 my-2">
                            <v-btn class="fs14" text color="#007AFF" @click="deshacer()" style="padding: 0 !important;">
                              <svg class="mr-2" width="15" height="17" viewBox="0 0 19 21">
                                <g id="trash-2" transform="translate(-2.5 -1.5)">
                                  <path id="Trazado_40875" data-name="Trazado 40875" d="M3,6H21" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                  <path id="Trazado_40876" data-name="Trazado 40876" d="M19,6V20a2,2,0,0,1-2,2H7a2,2,0,0,1-2-2V6M8,6V4a2,2,0,0,1,2-2h4a2,2,0,0,1,2,2V6" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                  <line id="Línea_284" data-name="Línea 284" y2="6" transform="translate(10 11)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                  <line id="Línea_285" data-name="Línea 285" y2="6" transform="translate(14 11)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                                </g>
                              </svg>
                              {{ $t('analisis.limpiar') }}
                            </v-btn>
                          </div>
                        </v-row>
                        <div class="content__pocillos">
                          <div>
                          <ul class="listnumber d-flex justify-content-space-around" v-if="(fila == 1)" >
                            <li  v-for="n in 8" :key="n" >{{n}}</li>
                          </ul>
                          <ul  v-else  class="listnumber--12 dflexbetween" >
                            <li v-for="n in 12" :key="n" >{{n}}</li>
                          </ul>
                          </div>
                        <div class="d-flex">
                            <div class="sticky df-column-space-around" v-if="(fila == 1)">
                              <p class="pt-2 pr-2">A</p>
                              <p class="pt-2 pr-2">B</p>
                            </div>
                            <div v-else class="sticky df-column-space-around" >
                              <p class="pt-2 pr-2">A</p>
                              <p class="pt-2 pr-2">B</p>
                              <p class="pt-2 pr-2">C</p>
                              <p class="pt-2 pr-2">D</p>
                              <p class="pt-2 pr-2">E</p>
                              <p class="pt-2 pr-2">F</p>
                              <p class="pt-2 pr-2">G</p>
                              <p class="pt-2 pr-2">H</p>
                            </div>
                            <v-col :class="getEquipo()">
                              
                                <div class="d-pocillo"
                                    v-for="pocillo in pocillos" :key="pocillo.id"
                                >
                                    <v-checkbox
                                        v-model="pocillo.checked"
                                        class="o-pocillos__checkbox"
                                        :color="color(pocillo)"
                                        @click="clickPocillo(pocillo)"
                                    ></v-checkbox>
                                    <div class=" v-pocillo">{{pocillo.data || ' '}}</div>
                                    <div class=" dn id-pocillo">
                                      {{pocillo.id}} {{( (pocillo.nameCheck != '')
                                      ?'('+ pocillo.nameCheck+')' : '')}}
                                    </div>
                                </div>
                            </v-col>
                        </div>
                        </div>
                        
                        <v-col cols="12" class="mt-5">
                            <p class="mb-0 px-6 c-text">
                                {{ $t('analisis.txt1') }}
                            </p>
                        </v-col>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn  
                        class="c-btn"
                        color="#007AFF"
                        outlined 
                        @click.native="close()">
                        {{ $t('analisis.cancelar') }}
                      </v-btn>
                      <v-btn  
                        class="mr-5 c-btn c-btn--blue bold"
                        outlined
                        @click.native="save()" >
                        {{ $t('analisis.analizar') }}
                      </v-btn>
                    </v-card-actions>
                  </v-col>

                </v-row>
              </v-card-text>
          </v-card>
      </v-dialog>
  </v-layout>
</template>

<script>
/* eslint-disable */
export default {
  data: () => ({
    //color: null,
    fila: null,
    dialog: false,
    data: null,
    dataDT: [],
    // kitsPcr: [],
    fila1: [],
    fila2: [],
    kitsPcr: [
      {
        id: 1,
        name: 'Steriplex Bacterias',
        id_ensayos: 994, // id ensayos [idali, idsuper, idmnip,idagua,idamb]
      },
      {
        id: 2,
        name: 'Steriplex hongos y levaduras',
        id_ensayos: 998, // id ensayos [idali, idsuper, idmnip,idagua,idamb]
      },
    ],
    pocillos: [
      {
        id: 1, data: null, analisis: null, checked: false, nameCheck: '',color: '',
      },
      {
        id: 2, data: null, analisis: null, checked: false, nameCheck: '',color: '',
      },
      {
        id: 3, data: null, analisis: null, checked: false, nameCheck: '',color: '',
      },
      {
        id: 4, data: null, analisis: null, checked: false, nameCheck: '',color: '',
      },
      {
        id: 5, data: null, analisis: null, checked: false, nameCheck: '',color: '',
      },
      {
        id: 6, data: null, analisis: null, checked: false, nameCheck: '',color: '',
      },
      {
        id: 7, data: null, analisis: null, checked: false, nameCheck: '',color: '',
      },
      {
        id: 8, data: null, analisis: null, checked: false, nameCheck: '',color: '',
      },
      {
        id: 9, data: null, analisis: null, checked: false, nameCheck: '',color: '',
      },
      {
        id: 10, data: null, analisis: null, checked: false, nameCheck: '',color: '',
      },
      {
        id: 11, data: null, analisis: null, checked: false, nameCheck: '',color: '',
      },
      {
        id: 12, data: null, analisis: null, checked: false, nameCheck: '',color: '',
      },
      {
        id: 13, data: null, analisis: null, checked: false, nameCheck: '',color: '',
      },
      {
        id: 14, data: null, analisis: null, checked: false, nameCheck: '',color: '',
      },
      {
        id: 15, data: null, analisis: null, checked: false, nameCheck: '',color: '',
      },
      {
        id: 16, data: null, analisis: null, checked: false, nameCheck: '',color: '',
      },
    ],
    desserts: [],
    selectedSample: null,
    controles: { cp: false, cn: false },
    actions: [],
    F: { dataForm: {} },
    equipo:1,
  }),
  computed: {
    OT() {
      let response = '';
      if (this.data) {
        response = this.data.id;
      }
      return response;
    },
    OTNSamples() {
      let response = '';
      if (this.data) {
        response = this.data.muestras_ots.length;
      }
      return response;
    },
    controlSelected() {
      let selected = null;
      
      if (this.controles.cp) {
        selected = 'cp';
        //this.color = 'success';
        //console.log(this.color);
      } else if (this.controles.cn) {
        selected = 'cn';
        //this.color = 'red';
        //console.log(this.color);
      }
      return selected;
    },
  },
  methods: {
    color(pocillo){
      let color = '';
      switch(pocillo.data)
        {
          case 'CN': color = 'red'; break;

          case 'CP':  color = 'success'; break;
        }

      return color;
    },
    /* eslint-disable no-param-reassign */
    /* eslint-disable arrow-body-style */
    /* eslint-disable array-callback-return  */
    /* eslint-disable brace-style  */

    clickSample(item) {
      this.data.muestras_ots.map((muestra) => {
        if (muestra.id !== item.id && muestra.vmodel === true) {
          muestra.vmodel = false;
        }
        return false;
      });
      if (item.vmodel === true) {
        this.selectedSample = item;
      } else {
        this.selectedSample = null;
      }
    },
    clickPocillo(pocillo) {
      // LIGA EL POCILLO CON LA MUESTRA
      if (this.selectedSample && this.controlSelected === null && pocillo.data === null) {
        this.saveAction(pocillo);
        pocillo.data = this.selectedSample.id;
        pocillo.muestra = this.selectedSample;
        // if (selected == 'cn'){
        //   pocillo.color = 'red'
        // }else{
        //   pocillo.color = 'success'
        // }

        // DESHABILITA LA MUESTRA Y SELECCIONA LA SIGUIENTE
        let selected = null;
        this.data.muestras_ots.map((muestra) => {
          if (
            !selected && muestra.disabled === false
            && muestra.id !== this.selectedSample.id
            && muestra.visible === true
          ) {
            muestra.vmodel = true;
            selected = muestra;
          } else if (muestra.id === this.selectedSample.id) {
            muestra.disabled = true;
            muestra.vmodel = false;
          }
        });

        this.selectedSample = selected;
      } else if (this.controlSelected !== null && pocillo.data === null) {
        this.saveAction(pocillo);
        pocillo.data = this.controlSelected.toUpperCase();
      } else if (this.controlSelected === null && this.selectedSample === null) {
        setTimeout(() => { pocillo.checked = false; }, 100);
      }
      // SELECTED DEL POCILLO
      // console.log('selected del pocillo');
      if (pocillo.data !== null) {
        setTimeout(() => { pocillo.checked = true; }, 100);
      }
    },
     
    checkCotrol(control, value) {
      if (value) {
        switch (control) {
          case 'cp': default: this.controles.cn = false;  
          //console.log(color); 
          break;
          case 'cn': this.controles.cp = false; 
          //console.log(color); 
          break;
        }
      }
    },
    saveAction(pocillo) {
      const poc = JSON.parse(JSON.stringify(pocillo));
      const obj = {
        pocillo: poc.id,
        // eslint-disable-next-line
        newValue: (this.controlSelected) ? this.controlSelected.toUpperCase() : this.selectedSample.id,
        lastValue: poc.data,
      };
      this.actions.push(obj);
      return obj;
    },
    deshacer() {
      // console.log('deshacer');
      if (this.actions.length > 0) {
        // VERIFICAR SI ES UNA MUESTRA PARA HABILITAR LA MUESTRA DE NUEVO
        const last = this.actions[this.actions.length - 1];
        // eslint-disable-next-line
        if (!isNaN(parseFloat(last.newValue))) {
          this.data.muestras_ots.map((muestra) => {
            muestra.vmodel = false;
            if (muestra.disabled === true && muestra.id === last.newValue) {
              muestra.disabled = false;
              muestra.vmodel = true;
              this.selectedSample = muestra;
            }
          });
        }
        // ELIMINAR VALOR DEL POCILLO
        this.pocillos.map((pocillo) => {
          if (pocillo.id === last.pocillo) {
            pocillo.data = null;
            pocillo.checked = false;
            pocillo.muestra = null;
            pocillo.nameCheck =  '';
            pocillo.color =  null;
          }
        });
        // ELIMINAR ULTIMO REGISTRO DE LAS ACCIONES
        this.actions.splice(this.actions.length - 1, 1);
      }
    },
    filtraEnsayos() {
      this.limpiaPocillos();
      this.limpiarMuestras();
      this.actions = [];
    },
    limpiaPocillos() {
      this.pocillos.map((pocillo) => {
        pocillo.data = null;
        pocillo.checked = false;
        pocillo.muestra = null;
        pocillo.nameCheck = '';
      });
    },
    limpiarMuestras() {
      const items = [];
      this.data.muestras_ots.map((muestra) => {
        muestra.vmodel = false;
        muestra.disabled = false;
        muestra.visible = false;
        if (this.F.dataForm.kitPcr) {
          const kitpcr = this.F.kitPCR.filter((kit) => kit.id === this.F.dataForm.kitPcr)[0];
          // kitpcr = kitpcr[0];
          let found = false;
          muestra.ensayo_muestras.map((ensayoMuestra) => {
            kitpcr.id_ensayos.map((kitEnsayo) => {
              if (kitEnsayo === ensayoMuestra.ensayo_id) {
                found = true;
              }
            });
          });
          if (found) {
            items.push(muestra);
            muestra.visible = true;
            if (items.length === 1) {
              muestra.vmodel = true;
              this.selectedSample = muestra;
            }
          }
        }
      });
    },
    //
    open(data, F) {
      this.dialog = true;
      this.limpiaPocillos();
      // console.log('open', [data, F]);
      data.muestras_ots.map((muestra) => {
        muestra.vmodel = false;
        muestra.disabled = false;
        muestra.visible = false;
      });
      this.controles.cp = false;
      this.controles.cn = false;

      this.F = F;
      this.data = data;
      this.equipo = this.F.dataForm.equipoKit;
      this.generarPosillos(this.F.dataForm.equipoKit);
      this.limpiarMuestras();
      // console.log('abrir pocillos', JSON.parse(JSON.stringify(this.pocillos)));
    },
    close() {
      this.dialog = false;
    },
    save() {
      // this.radios = 1;
      const infoData = this.data;
      const newObj = [];
      this.pocillos.map((pocillo) => {
        // SI NO ES NULO
        if (pocillo.data !== null) {
          const obj = {
            pocillo:
            pocillo.id,
            muestra: null,
            control: null,
            local: infoData.local,
          };
          // SI VIENE DE UNA MUESTRA
          if (pocillo.muestra) {
            //console.log({ELOBJETODELAMUESTRA:pocillo})
            obj.muestra = pocillo.muestra;
          }
          // SI ES DE UN CONTROL
          else {
            obj.control = pocillo.data;
          }
          newObj.push(obj);
        }
      });

      // this.F.local = ((infoData.local) ? infoData.local.nombre_local : '');
      this.F.analizarMuestras(newObj);
      this.dialog = false;
    },
    generarPosillos(item)
    {
      // console.log("kitpcr = "+item);
      this.fila = item;
      let posi = 16;
      let abc = [''];
      let j = 1;
      if (item == 2)
      {
        posi = 12;
        abc = ['A','B','C','D','E','F','G','H']
      }
      const posiArray = [];
      abc.forEach(function (index) {
        for (let i = 1; i <= posi; i++) {
          const filaposillo = {
            id: j, data: null, analisis: null, checked: false, nameCheck: ( (abc.length>1) ? index+i : ''),
          };
          posiArray.push(filaposillo);
          j++;
        }
      });
      
      this.pocillos = posiArray;
      // return fila;
    },
    getEquipo(){
      let clase = this.equipo==1 ? 'o-pocillos' : 'o-pocillos-12';
      return clase;
    },
  },
};
</script>
